import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {
    contHeight: 0,
    savedPosition: 0
  },
  getters: {
    GE_CONT_HEIGHT: state => state.contHeight,
    GE_SAVED_POSITION: state => state.savedPosition
  },
  mutations: {
    MU_CONT_HEIGHT: (state, payload) => {
      state.contHeight = payload;
    },
    MU_SAVED_POSITION: (state, payload) => {
      state.savedPosition = payload;
    }
  },
  actions: {
    AC_CONT_HEIGHT: ({commit}, payload) => {
      commit("MU_CONT_HEIGHT", payload);
    },
    AC_SAVED_POSITION: ({commit}, payload) => {
      commit("MU_SAVED_POSITION", payload);
    }
  }
});
