<template>
  <component :is="AppLayout" :key="$route.meta.layout" />
</template>
<script>
  import "swiper/css/swiper.css";
  import {layouts} from "@/utils/global-methods";

  export default {
    name: "App",
    metaInfo: {
      title: "e · motion",
      titleTemplate: "%s | Full service Digital Agency"
    },
    computed: {
      AppLayout() {
        return `Layout${this.$route.meta.layout || "Default"}`;
      }
    },
    components: {
      LayoutDefault: layouts("app-default"),
      LayoutClean: layouts("app-clean")
    },
    methods: {
      urlCheck() {
        const filter = "win16|win32|win64|macintel|mac|";
        if (navigator.platform) {
          if (filter.indexOf(navigator.platform.toLowerCase()) >= 0) {
            // if (window.location.hostname === "devm.emotion.co.kr") {
            //   document.location = `http://dev.emotion.co.kr${window.location.pathname}${window.location.search}`;
            // }
            if (window.location.hostname === "m.emotion.co.kr") {
              document.location = `https://www.emotion.co.kr${window.location.pathname}${window.location.search}`;
            }
          }
        }
      }
    },
    created() {
      this.urlCheck();
    }
  };
</script>
<style lang="scss" src="./assets/scss/common.scss" />
