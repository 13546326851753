<template>
  <section class="modal-wrap">
    <div class="modal-header">
      <slot name="header"></slot>
    </div>
    <perfect-scrollbar class="modal-contents" :scrollingThreshold="null">
      <slot></slot>
    </perfect-scrollbar>
    <div class="modal-footer">
      <slot name="footer"></slot>
    </div>
    <button type="button" class="modal-close" @click="$emit('close')">
      닫기
    </button>
  </section>
</template>

<script>
export default {
  name: 'modal-wrap',
  mounted() {
    const app = document.querySelector('#app');
    const winScrollTop = window.pageYOffset;
    app.classList.add('modal-open');
    app.scrollTop = winScrollTop;
  },
  destroyed() {
    const app = document.querySelector('#app');
    const appScrollTop = app.scrollTop;
    app.classList.remove('modal-open');
    window.scrollTo(0, appScrollTop);
  },
};
</script>
<style scoped lang="scss">
.modal-wrap {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  min-height: 300px;
  width: 100%;
  background: #fff;
}
.modal-header {
  flex: 0 0 auto;
  padding: 10px;
}
.modal-contents {
  flex: 1 1 auto;
  overflow: auto;
  padding: 10px;
  line-height: 30px;
  -webkit-overflow-scrolling: touch;
}
.modal-footer {
  flex: 0 0 auto;
  padding: 10px;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
