<template>
  <span :class="{'form-select': true, tab: type === 'tab', open: open}">
    <button
      ref="select"
      type="button"
      @click="selectOpen"
      @focusout="open = false"
      @keydown="keydown($event)"
      :class="{'non-select': !value}"
    >
      <span>{{ selectedLabel }}</span>
    </button>
    <ul v-show="open">
      <li
        v-for="(item, index) in options"
        :class="{active: activeClass(item), disabled: item.disabled}"
        :key="index"
        @mouseup="listClick(item)"
        @mousedown.prevent=""
      >
        <label>
          <input type="hidden" :value="item.value" />
          {{ item.label }}
        </label>
      </li>
    </ul>
  </span>
</template>
<script>
  export default {
    name: "Select",
    data() {
      return {
        open: false,
        focused: false
      };
    },
    props: {
      placeholder: String,
      value: String,
      type: String,
      options: Array,
      required: Boolean,
      disabled: Boolean
    },
    computed: {
      selectedLabel() {
        const idx = this.options.findIndex(el => el.value === this.value);
        return this.options[idx]?.label || this.placeholder;
      }
    },
    model: {
      event: "change"
    },
    mounted() {
      this.$emit("change", this.value);
    },
    methods: {
      keydown(e) {
        if (
          e.keyCode === 32 ||
          e.keyCode === 37 ||
          e.keyCode === 38 ||
          e.keyCode === 39 ||
          e.keyCode === 40
        ) {
          e.preventDefault();
        }
        if (e.keyCode === 32) {
          this.open = true;
        }
        if (e.keyCode === 37 || e.keyCode === 38) {
          this.keyUpDown(true);
        }
        if (e.keyCode === 39 || e.keyCode === 40) {
          this.keyUpDown(false);
        }
      },
      keyUpDown(state) {
        const selectIndex = this.options.findIndex(el => el.value === this.value);
        if (state && selectIndex !== 0) {
          this.$emit("change", this.options[selectIndex - 1]?.value);
        } else if (!state && selectIndex !== this.options.length - 1) {
          this.$emit("change", this.options[selectIndex + 1]?.value);
        }
      },
      listClick(item) {
        this.$refs.select.focus();
        this.open = false;
        this.$emit("change", item.value);
        if (document.querySelector(".tab-contents") && this.type === "tab") {
          this.$nextTick(() => {
            if (document.querySelector(".tab-contents").offsetTop <= window.scrollY + 1) {
              const offset = document.querySelector(".tab-contents").offsetTop + 1;
              window.scrollTo(0, offset);
            }
          });
        }
      },
      activeClass(item) {
        return this.value === item.value && !item.disabled;
      },
      selectOpen() {
        this.open = !this.open;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .form-select {
    position: relative;
    display: inline-block;
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    &.open {
      button {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    button {
      position: relative;
      text-align: left;
      border: none;
      vertical-align: top;
      line-height: inherit;
      padding: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      background: #fff;
      span {
        position: relative;
        color: #000;
        font-size: 11px;
        line-height: 11px;
        font-family: "Noto Sans KR", sans-serif;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 16px;
        height: 9px;
        background: url(../../assets/images/career/icon-arrow.png) no-repeat center/cover;
      }
      &.non-select {
        span {
          color: #b4b4b4;
        }
      }
    }
    ul {
      z-index: 1;
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      font-size: 14px;
      font-weight: normal;
      line-height: 40px;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #ddd;
      li {
        user-select: none;
        padding: 0 10px;
        &.active {
          background: #f6f6f6;
        }
      }
      li + li {
        border-top: 1px solid #ddd;
      }
    }
    &.tab {
      z-index: 16;
      position: sticky;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      height: auto;
      padding: 16px 72px 0 0;
      box-sizing: border-box;
      border-bottom: 0;
      &.open {
        button {
          &:after {
            top: 17px;
            transform: rotate(180deg);
          }
        }
      }
      button {
        width: auto;
        height: 44px;
        padding: 0 35px 0 15px;
        cursor: pointer;
        user-select: none;
        border: 1px solid #ddd;
        border-radius: 30px;
        background: #fff;
        box-sizing: border-box;
        span {
          color: $red;
          font-weight: bold;
          font-size: 13px;
          line-height: 1;
          @include montserrat;
        }
        &:after {
          right: 15px;
          width: 10px;
          height: 5px;
          background: url(../../assets/icons/ic-arrow-down.svg) no-repeat center/cover;
        }
      }
      ul {
        position: absolute;
        top: 100%;
        width: auto;
        left: auto;
        right: 72px;
        padding: 10px 0;
        margin-top: 5px;
        background: #fff;
        border-radius: 15px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        overflow: hidden;
        @include montserrat;
        font-weight: bold;
        font-size: 13px;
        color: #a0a0a0;
        line-height: 1;

        li {
          + li {
            border-top: none;
          }
          height: 44px;
          padding: 15px 35px 15px 15px;
          box-sizing: border-box;
          &.active {
            color: $red;
            background: #fff;
          }
        }
      }
    }
  }
</style>
