<template>
  <label
    v-if="checkedType"
    :class="{
      'form-checkbox': true,
      disabled: disabled,
      checked: checked,
      focused: focused
    }"
  >
    <input
      ref="checkbox"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked, $event)"
      @blur="focused = false"
      @focus="focused = true"
    />

    <i></i>
    <slot></slot>
  </label>
  <label
    v-else
    :class="{
      'form-radio': true,
      disabled: disabled,
      checked: checked.some((el) => el === val),
      focused: focused
    }"
  >
    <input
      ref="checkbox"
      type="checkbox"
      :checked="checked.some((el) => el === val)"
      :disabled="disabled"
      @change="handleChange"
      @blur="focused = false"
      @focus="focused = true"
    />
    <i></i>
    <slot></slot>
  </label>
</template>
<script>
export default {
  name: "Checkbox",
  data() {
    return {
      focused: false
    };
  },
  computed: {
    checkedType() {
      return typeof this.checked === "boolean";
    }
  },
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    checked: [Boolean, Array],
    disabled: Boolean,
    val: String
  },
  methods: {
    handleChange(e) {
      const idx = this.checked.indexOf(this.val);
      if (idx !== -1) {
        this.checked.splice(idx, 1);
      } else {
        this.checked.push(this.val);
      }
      this.$emit("change", this.checked, e);
    }
  }
};
</script>
<style scoped lang="scss">
label {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  min-height: 19px;
  color: #333;
  font-size: 13px;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }
  i {
    height: 19px;
    width: 19px;
    margin-right: 12px;
    border-radius: 100%;
    background: url(../../assets/images/check-off.png) no-repeat 0 / cover;
  }
}
label.disabled {
  cursor: default;
  i {
    border-color: #ddd;
    background: #ddd;
  }
}
label.checked {
  i {
    background: url(../../assets/images/check-on.png) no-repeat 0 / cover;
  }
  &.disabled {
    opacity: 0.5;
  }
}
</style>
