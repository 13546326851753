<template>
  <div class="tab-top">
    <div class="tab-list">
      <ul>
        <li
          ref="tabItem"
          v-for="(item, index) in list"
          :key="index"
          :class="{on: active === item.id}"
        >
          <button @click="tabEvent(item.id)">
            <span v-html="item.title" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: "tab",
    model: {
      prop: "active",
      event: "activeChange"
    },
    props: {
      list: Array,
      active: String,
      name: {
        type: String,
        default: "tab"
      }
    },
    methods: {
      tabEvent(id) {
        this.$emit("activeChange", id);
      }
    }
  };
</script>
<style scoped lang="scss">
  .tab-top {
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 18px;
    padding-top: 50px;
    &.flex-end {
      .tab-list {
        width: 100%;
        justify-content: flex-end;
      }
    }
    .tab-list {
      display: flex;
      justify-content: flex-start;
      padding: 0 20px;
      will-change: transform;
      box-sizing: border-box;
      cursor: pointer;
      ul {
        justify-self: flex-end;
        display: flex;
        li {
          &.on {
            button {
              color: $red;
              &:after {
                width: 100%;
                background: $red;
              }
            }
          }
          button {
            @include montserrat;
            font-weight: bold;
            font-size: 14px;
            line-height: 1;
            color: #a0a0a0;
            border: none;
            background: none;
            padding: 0;
            &:after {
              display: block;
              content: "";
              margin-top: 2px;
              width: 0;
              height: 2px;
              background: #a0a0a0;
              transition: width 250ms ease-in-out;
              transform-origin: 0 0;
            }
            &:hover:after {
              width: 100%;
            }
          }
          + li {
            margin-left: 25px;
          }
        }
      }
    }
  }
</style>
