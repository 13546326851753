<template>
  <label
    :class="{
      'form-radio': true,
      disabled: this.disabled,
      checked: this.checked,
      focused: focused
    }"
  >
    <input
      ref="radio"
      type="radio"
      :checked="checked"
      :disabled="disabled"
      :name="name"
      :value="val"
      @change="$emit('change', val, $event)"
      @blur="focused = false"
      @focus="focused = true"
    />
    <!--    <i></i>-->
    <span>
      <slot></slot>
    </span>
  </label>
</template>
<script>
  export default {
    name: "Radio",
    data() {
      return {
        focused: false
      };
    },
    computed: {
      checked() {
        return this.val === this.value;
      }
    },
    model: {
      event: "change"
    },
    props: {
      value: String,
      val: String,
      name: String,
      disabled: Boolean
    }
  };
</script>
<style lang="scss" scoped>
  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
  label.disabled {
    cursor: default;
    i {
      border-color: #ddd;
      background: #ddd;
    }
  }
  label.checked {
    i {
      background: red;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
</style>
